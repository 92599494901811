<template>
  <div class="permissoes">
    <v-container
      fluid
    >
      <div class="header">
        <h1 class="titulo"><span>Grupo:</span> {{ item.name }}</h1>
        <!-- <v-btn @click="salvarEndpoints">Salvar</v-btn> -->
        <v-btn color="secondary" @click="salvarPermissoes" class="br-btn" :loading="loading" v-if="permiteAcao($route, 'add')">Salvar</v-btn>
      </div>

      <v-row v-if="permissoes">
        <v-col cols="12" sm="3" v-for="modules in permissoes" :key="modules.id">
          <v-expansion-panels
            tile
            class="permissoes-panel"
          >
            <v-expansion-panel tile>
              <v-expansion-panel-header hide-actions>
                <v-icon class="permissoes-panel-icon permissoes-panel-icon--expanded">keyboard_arrow_down</v-icon>
                {{ modules.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-list-item class="permissoes-lista" v-for="(group, key) in modules.groups" :key="group.id">
                    <v-list-item-content>
                      <v-list-item-title :class="{ 'subitem': group[0].subItem }">
                        {{ group[0].subItem ? '-' : '' }} {{ key }}
                        <v-menu 
                          :close-on-content-click="false"
                          offset-x
                          :nudge-width="100"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn text icon>
                              <v-icon v-on="on">create</v-icon>
                            </v-btn>
                          </template>

                          <v-card>
                            <v-list class="permissoes-lista-menu">
                              <v-list-item v-for="itemCheckbox in group" :key="itemCheckbox.id">
                                <v-tooltip
                                  right
                                  :disabled="(itemCheckbox.namePai) ? false : true"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-list-item-title v-on="on">
                                      <v-checkbox
                                        :class="{strong: itemCheckbox.namePai}"
                                        :disabled="itemCheckbox.disableByDad"
                                        @click="ts(itemCheckbox)"
                                        :label="itemCheckbox.path"
                                        :indeterminate="itemCheckbox.allowed === null ? true : false"
                                        :name="itemCheckbox.id"
                                        :input-value="itemCheckbox.allowed"
                                        :value="itemCheckbox.allowed"
                                        color="secondary"
                                      ></v-checkbox>
                                    </v-list-item-title>
                                  </template>
                                  <span>{{ itemCheckbox.namePai }}</span>
                                </v-tooltip>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { map, groupBy, filter, assignIn, size, compact, orderBy, uniqBy } from 'lodash'
import Events from '@/core/service/events'

export default {
  name: 'Permissoes',
  data: () => ({
    panel: null,
    menu: false,
    checkbox: [],
    permissoes: null,
    mapListaByGroups: null,
    loading: false
  }),
  methods: {
    ...mapActions('permissoes', ['getModules', 'listByGroup', 'addAll']),
    ...mapActions('roles', ['buscarGrupo']),
    ...mapGetters('roles', ['permiteAcao']),
    ts(checkbox) {
      switch (checkbox.allowed) {
        case false:
          checkbox.allowed = null
          return null
        case true:
          checkbox.allowed = false
          return false
        default:
          checkbox.allowed = true
          return true
      }
    },

    salvarPermissoes () {
      const permissoesSemPai = filter(this.mapListaByGroups, item => {
        return item.allowed !== item.valorPai
      })

      const dados = map(permissoesSemPai, item => {
        let a = {
          roleId: this.idGrupo,
          moduleId: item.id,
          allowed: item.allowed
        }
        if (item.idRoleModules !== null) {
          return assignIn({id: item.idRoleModules}, a)
        }
        return a
      })

      let dadosFilter = filter(dados, itemFilter => {
        return  (!(itemFilter.allowed === null && typeof itemFilter.id === "undefined"))
      })

      if (size(dadosFilter)) {
        this.loading = true
  
        // Atualiza as permissões
        this.addAll(dadosFilter).then(() => {
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Permissões atualizadas com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
          this.loading = false
          this.listarEndpoints()
        }).catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg
          }
          Events.$emit('snackbarCadastro::msg', msg)
          this.loading = false
        })
      }



    },

    listarEndpoints () {
      this.getModules().then(modules => {
        this.listByGroup({ roleId: this.idGrupo }).then(listByGroups => {
          // mapeando a lista de endpoints com as permissao
          this.mapListaByGroups = orderBy(map(listByGroups, item => {
            const arrayPath = compact(item.path.split('/'))
            return {
              id: item.id,
              path: item.path,
              name: item.name,
              groupId: item.moduleGroupId,
              allowed: (item.roleModules.length) ? item.roleModules[0].allowed : null,
              idRoleModules: (item.roleModules.length) ? item.roleModules[0].id : null,
              disableByDad: this.isDisabledByDad(item, this.idGrupo),
              namePai: this.getNamePai(item),
              valorPai: (item.roleModules.length) ? item.roleModules[0].allowed : null,
              subItem: arrayPath.length > 1 ? true : false
            }
          }), 'path', 'esc')


          let aclUniqFiltered = this.acl
            .map(item => ({
              name: item.group,
              allowed: item.allowed
            }))
            .filter(item => item.allowed)

          aclUniqFiltered = uniqBy(aclUniqFiltered, 'name')

          const aclUniqFilteredArr = []
          aclUniqFiltered.forEach(item => {
            aclUniqFilteredArr.push(item.name)
          })

          const modulesFiltered = modules.filter(item => aclUniqFilteredArr.includes(item.name))

          this.permissoes = map(modulesFiltered, moduleItem => { 
            // mapeando os grupos
            return {
              id: moduleItem.id,
              name: moduleItem.name,
              situacao: moduleItem.situacao,
              groups: groupBy(filter(this.mapListaByGroups, itemFilter => { // agrupando os endpoints por name e filtrando os andpoints pertencente de cada grupo
                return itemFilter.groupId === moduleItem.id
              }), groupByItem => {
                return groupByItem.name
              })
            }
          })
        })
      })
    },

    // para desabilitar um checkbox, retorna true quando uma permissão é nega no pai
    isDisabledByDad (val) {
      return (size(val.roleModules) && val.roleModules[0].roleId !== this.idGrupo && val.roleModules[0].allowed === false) ? true : false
    },

    // Retorna o nome do pai se a permissão vim dele 
    getNamePai(val) {
      return (size(val.roleModules) && val.roleModules[0].roleId !== this.idGrupo) ? val.roleModules[0].role.name : null
    }
  },
  computed: {
    // ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('roles', ['item', 'acl']),
    ...mapGetters('permissoes', ['listaModules']),
    idGrupo () {
      return parseInt(this.$route.params.id) || null
    }
  },
  mounted () {
    this.buscarGrupo({ id: this.idGrupo })
    this.listarEndpoints()
  }
    
}
</script>

<style lang="scss">
  .permissoes {
    border-radius: 0;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .titulo {
        margin-bottom: 0;
      }
    }

    &-panel {
      .v-expansion-panel-header {
        font-size: 14px;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
      }

      &-icon {
        &--expanded {
          flex-grow: 0 !important;
          margin-right: 10px;
        }
      }

      .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 48px;
        // margin-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      .v-expansion-panels:not(.v-expansion-panels--accordion) > .v-expansion-panel--active {
        border-radius: 0;
      }

    }
    &-lista {
      .v-list-item__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .v-icon {
          font-size: 15px;
        }
      }

      &-menu {
        .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
          margin-bottom: 0;
        }

        .v-messages {
          display: none;
        }

        .v-input--selection-controls {
          margin-top: 0;
          padding-top: 0;
        }

        .v-input--selection-controls__input {
          min-height: 24px;
        }

        .v-list-item {
          min-height: 24px;
          padding: 5px 15px;
        }

        .strong {
          font-weight: bold;
          .v-label {
            color: #000;
          }

          &.theme--light.v-input--is-disabled .v-label {
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
    }

    .subitem {
      padding-left: 10px;
    }
  }
</style>